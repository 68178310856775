import React, {Component} from 'react';

import RecordForm from './RecordForm';
import RecordTable from './RecordTable';
import {get_records} from "../../../api/api";



export default class Record extends Component {
    constructor(props) {
        super(props);
        this.state = { recordData: []};
    }

    componentDidMount() {
        get_records()
        .then( (records) => {
            this.setState( {recordData: records});
        });
    }

    onTableLoad(recordData) {
        if (!recordData) {
            get_records()
            .then( (records) => {
                this.setState( {recordData: records});
            });
        }
        else {
            this.setState( {recordData: recordData});
        }
    }

    render() {
        return (
            <div>
                <RecordForm onTableLoad={this.onTableLoad.bind(this)}/>
                <RecordTable title={"Records"} search={true} buttons={["Save", "Delete"]} headers={["Online Monitor Id", "Online Monitor Type", "Online Monitor Name", "GATS Name", "NON Number", "API KEY", "Status", ""]} tableData={this.state.recordData} onTableLoad={this.onTableLoad.bind(this)}/>
            </div>
        )
    }

}