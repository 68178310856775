import React, {Component} from 'react';

import "./Form.css";

class Form extends Component {
    constructor(props) {
        super(props);
        this.type = props.type;
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.reader = new FileReader();
    }



    handleFileChange(event) {
        event.preventDefault();
        let name = event.target.name;
        let change = this.state;

       // console.log( this.reader.readAsBinaryString(event.target.files[0]))
        //this.reader.onload = () => change.formData[name] = this.reader.result;
        change['formData'][name] = event.target.files[0];
        this.setState(change);
    }

    handleChange(event) {
        let change = {formData: {...this.state["formData"]}};
        change.formData[event.target.name] = event.target.value;
        this.setState(change);
    }

    handleSubmit(event) {
        event.preventDefault();
        for (let key of Object.keys(this.state.formData)) {
            if (!this.state.formData[key]) {
                document.getElementById("record-error").classList.remove('hidden');
                return false;
            }
        }
        document.getElementById("record-error").classList.add('hidden');
        return true;
    }


    render() {
        return (
            <form className="form" onSubmit={this.handleSubmit}>
            </form>
        )
    }

}


export default Form;






