
export const objectToArray = function(order) {
    if (order) {
         return function(object) {
             return order.map( (entry) => {
                 return object[entry];
             });
         }
    }
    return function(object) {
        return Object.keys(object).sort().map((key) => {
            return object[key];
        });
    }
};






