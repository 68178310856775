import React, {Component} from 'react';

import SearchBox from './SearchBox';

import './Table.css';

export default class Table extends Component {
    renderHeaderRow() {
        const {headers} = this.props || null;
        if (headers) {
            return (<tr key={"header"}>
                {
                    headers.map( (header) => {
                        return <Cell key={header} header={true} value={header} />
                    })
                }
                </tr>  )
        }
    }


    renderBody() {
        const {tableData} = this.props;
        return tableData.map( (row, row_index) => {
            return (
                <tr key={`${row_index}`}>
                {
                    row.map( (cell, col_index) => {
                        return (
                            <Cell key={`${row_index}-${col_index}`} value={row[col_index]} />
                        )
                    })
                }
                <Cell key={`${row_index}-button-cell`} value={""} buttons={this.props.buttons}/>
            </tr>);
        })
    };



    render() {
      const {title} = this.props;
      const tableHeader = this.renderHeaderRow();
      const tableBody = this.renderBody();
      return <div className="table-wrapper"> {
            ((this.props.search === true) && <table className="table-header"><thead><tr key={"header-row"}><td key={"title"}><h3>{title}</h3></td><td key={"search"}><SearchBox onTableLoad={this.props.onTableLoad.bind(this)}/></td></tr></thead></table>) || (<h3>{title}</h3>)
            }
            <table className="table">
                <thead>{tableHeader}</thead>
                <tbody>{tableBody}</tbody>
            </table>
        </div>

    };
}


export const Cell = function(props) {
    if (props.header) {
        return (
        <th className="cell">{props.value}</th>
        )
    }
    else if (props.buttons) {
        return <td>
            <table className={"no-border action-table"}><tbody>{props.buttons.map( (buttonName) => { return <tr key={`${buttonName}-button-row`}><td><button>{buttonName}</button></td></tr>})}</tbody></table>
        </td>
    }
    return (
        <td className="cell">{props.value}</td>
    );
};

