import React from 'react';
import Table from '../../util/Table';

import './UploadTable.css';
import {CSVLink} from "react-csv";

import {get_uploads} from "../../../api/api";

export default class UploadTable extends Table {
    renderBody() {
        const {tableData} = this.props;
        if (tableData) {
            return Object.keys(tableData).map((year, index) => {
                const yearData = tableData[year];
                const diff = 12 - Object.keys(yearData).length ;
                return (<tr key={year}>{
                    Object.keys(yearData).sort().map((month, index) => {
                        const result = yearData[month].ResultBody;
                        const error = yearData[month].ErrorBody;
                        return <td key={month}><MonthTable monthGen={`${month}-${year}`} resultData={result} errorData={error}/></td>
                    }).concat([...Array(diff).keys()].map( (index) => { return <td key={index}></td>}))
                }</tr>);

            })
        }

    }




    componentDidMount() {
        get_uploads()
        .then( (body) => {
            this.props.onTableLoad(body.value);
        })
    }

}




const MonthTable = (props) => {
    return (<div>
        <h4>{props.monthGen}</h4>
        <table>
        <tbody>
            <tr>
                <td><CSVLink className="csv-button" filename={`${props.monthGen}-results.csv`} data={props.resultData}>Results</CSVLink></td>
                <td><CSVLink className="csv-button" filename={`${props.monthGen}-errors.csv`} data={props.errorData}>Errors</CSVLink></td>
            </tr>
        </tbody>
        </table>
    </div>)
};