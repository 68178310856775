import React from 'react';
import Form from "../../util/Form";

import {Container, Col, Row} from 'reactstrap';
import "./RecordForm.css";

import { push_records, get_records, update_record, delete_record } from "../../../api/api";


export default class RecordForm extends Form {
    constructor(props) {
        super(props);
        this.state = {uploadRunning: false,formData: {Online_Monitor_Id: "", Online_Monitor_Type: "ENPHASE", Online_Monitor_Name: "", GATS_Name: "", Non_Number: "", Status: "ACTIVE"}}
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.formData.Online_Monitor_Id === "" || this.state.Online_Monitor_Type === "" || this.state.Online_Monitor_Name === "" || this.state.Status === "") {
            document.getElementById("record-error").classList.remove('hidden');
        }
        else {
            document.getElementById("record-error").classList.add('hidden');
            let records = [this.state.formData, ];
            push_records(records)
            .then( (response) => {
                get_records().then( (records) => {
                    this.props.onTableLoad(records)
                    this.setState({formData: {Online_Monitor_Id: "", Online_Monitor_Type:  "ENPHASE", Online_Monitor_Name: "", Non_Number: "", GATS_Name: "", Status: "ACTIVE", API_KEY: "" }})
                })
            });
        }
    }

    render() {
        return (
            <form autoComplete="off" onSubmit={this.handleSubmit}>
                <div id="record-error" className="hidden">Error Submitting Form</div>
                <label>
                    Online Monitor Id:
                    <input name="Online_Monitor_Id" type="text" value={this.state.formData.Online_Monitor_Id} onChange={this.handleChange}/>
                </label>
                <label>
                    Online Monitor Type:
                    <select name="Online_Monitor_Type" value={this.state.formData.Online_Monitor_Type} onChange={this.handleChange}>
                        <option value={"ENPHASE"}>ENPHASE</option>
                        <option value={"SOLAR EDGE"}>SOLAR EDGE</option>
                        <option value={"GROWATT"}>GROWATT</option>
                    </select>
                </label>
                <label>
                    Online Monitor Name:
                    <input name="Online_Monitor_Name" type="text" value={this.state.formData.Online_Monitor_Name} onChange={this.handleChange}/>
                </label>
                <label>
                    GATS Name:
                    <input name="GATS_Name" type="text" value={this.state.formData.GATS_Name} onChange={this.handleChange}/>
                </label>
                <label>
                    Non Number:
                    <input name="Non_Number" type="text" value={this.state.formData.Non_Number} onChange={this.handleChange}/>
                </label>
                <label>
                    Status:
                    <select name="Status"  value={this.state.formData.Status} onChange={this.handleChange}>
                        <option value={"ACTIVE"}>ACTIVE</option>
                        <option value={"MISSING"}>MISSING</option>
                        <option value={"NOT REPORTING"}>NOT REPORTING</option>
                    </select>
                </label>
                <label>
                    API KEY:
                    <input name="API_KEY" type="text" value={this.state.formData.API_KEY} onChange={this.handleChange}/>
                </label>
                <input className={"button-submit"} type="submit" value="Save" disabled={this.state.uploadRunning}/>
            </form>
        );
    }
}


export class RecordUpdateForm extends Form {
    constructor(props) {
        super(props);
        let {input} = props;
        this.state = {formData: {Online_Monitor_Id: input[0], Online_Monitor_Type: input[1], Online_Monitor_Name: input[2], GATS_Name: input[3], Non_Number: input[4],Status: input[5], API_KEY: input[6]}};
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({uploadRunning: true});
        let method = event.target.name;
        if (this.state.formData.Online_Monitor_Id === "" || this.state.Online_Monitor_Name === "" || this.state.Status === "") {
            document.getElementById("record-error").classList.remove('hidden');
        }
        else {
            document.getElementById("record-error").classList.add('hidden');
            if (method === 'save') {
                update_record(this.state.formData.Online_Monitor_Id, this.state.formData).then( () => {
                    this.props.onTableLoad(null);
                    this.setState({uploadRunning: false});
                })
            }
            else if (method === 'delete') {
                delete_record(this.state.formData.Online_Monitor_Id, this.state.formData.Online_Monitor_Type)
                .then( () => {
                    this.props.onTableLoad(null);
                    this.setState({uploadRunning: false});
                })
            }
        }
    }

    render() {
        let {input} = this.props;
       return  <form autoComplete="off" className="update-record-form">
           <Row>
            <div className="record-error hidden">Error Submitting Form</div>
               <Col className={"col-small"}><div>{this.state.formData.Online_Monitor_Id}</div></Col>
               <Col className={"col-small"}><div>{this.state.formData.Online_Monitor_Type}</div></Col>
               <Col className={"col-small"}><input name="Online_Monitor_Name" type="text" value={this.state.formData.Online_Monitor_Name} onChange={this.handleChange}/></Col>
               <Col className={"col-small"}><input name="GATS_Name" type="text" value={this.state.formData.GATS_Name} onChange={this.handleChange}/></Col>
               <Col className={"col-small"}><input name="Non_Number" type="text" value={this.state.formData.Non_Number} onChange={this.handleChange}/></Col>
               <Col className={"col-large"}><input type="text" name="API_KEY" value={this.state.formData.API_KEY} onChange={this.handleChange}/></Col>
               <Col className={"col-small"}><input name="Status" type="text" value={this.state.formData.Status} onChange={this.handleChange}/></Col>
               <Col><input onClick={this.handleSubmit} disabled={this.state.uploadRunning} type="submit" name="save" value="Save" /><input disabled={this.state.uploadRunning} onClick={this.handleSubmit} type="submit" name="delete" value="Delete"/></Col>

           </Row>
           <hr/>
        </form>
    }
}
