import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link} from "react-router-dom";


import UploadPage from "./components/Pages/UploadPage/Upload";
import RecordPage from "./components/Pages/RecordPage/Record";

import './App.css';
import './Navbar.css';


class App extends Component {
  render() {
    return (
      <div className="App">
          <Navbar/>



      </div>
    );
  }
}

class Navbar extends Component {
    render() {
        return (
            <Router basename={"/online-monitoring"}>
            <div className="nav-div">
                <nav className='navv'>
                    <div className="navbar-inner">
                        <div className='nav-logo-image'>
                            <img src={process.env.PUBLIC_URL + "/img/flettexchange.png"} alt='FLETT EXCHANGE'/>
                        </div>
                        <div className="navbar-navv">
                            <ul>
                                <li className='navbar-button'><Link to={"/"}><p>Uploads</p></Link></li>
                                <li className='navbar-button'><Link to={"/records"}><p>Records</p></Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="App-header">
                <Route path="/" exact component={UploadPage}/>
                <Route path="/records" component={RecordPage}/>
                </div>
            </div>
            </Router>
        );
    }
}

export default App;
