import React, {Component} from 'react';

import UploadForm  from './UploadForm';
import UploadTable from './UploadTable';


import './Upload.css';
import {get_uploads} from "../../../api/api";

class UploadPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadData: [],
            error: ""
        }
    }

    onFormSuccess() {
        get_uploads()
        .then( (body) => {
            this.onTableLoad(body.value);
        })
    }

    onTableLoad(uploadData) {
        this.setState({uploadData: uploadData});
    }


    render() {
         let error = this.state.error;
        return (
            <div id="upload-page">
                <UploadForm type={"upload"} onFormSuccess={this.onFormSuccess.bind(this)} parentError={error} />
                <UploadTable search={false} title={"Uploads"} tableData={this.state.uploadData} onTableLoad={this.onTableLoad.bind(this)}/>
            </div>
        )
    }

}

export default UploadPage;
