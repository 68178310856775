import React from 'react';

import './UtilComponents.css';

export function TestButton(props){
    if (process.env.REACT_APP_Stage === 'DEV') {
        return (
            <div>
                <button id="test-fill-upload" type="button" onClick={props.handleClick}>Fill Fields</button>
            </div>
        )
    }
    else return null;
}

export function ErrorBox(props) {
    return <div className={'error-box'}>
        {props.message}
    </div>
}



