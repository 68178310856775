import React from 'react';
import Form from "../../util/Form";

import {push_uploads} from "../../../api/api";

import "./UploadForm.css";
import {ErrorBox, TestButton} from "../../util/UtilComponents";

export default class UploadForm extends Form {
    constructor(props) {
        super(props);
        this.state = { error: "",spinnerActive: false, formData: { solarEdgeApiCheckbox: true,solarEdgeCheckbox: false, growattCheckbox: false, enphaseCheckbox: false,EnphaseFileField: "", SolarEdgeFileField: "",GATSFileField: "", dateOfReading: "", monthOfGeneration: ""}};
        this.toggleCheckBox = this.toggleCheckBox.bind(this);
        this.handleTestButtonClick = this.handleTestButtonClick.bind(this);
    }

    toggleCheckBox(value) {
        let state = { formData: {...this.state.formData}};
        state.formData[value] = !this.state.formData[value];
        this.setState(state);
    }

    handleTestButtonClick() {
        let formData = this.state.formData;
        formData.dateOfReading = "2018-09-01";
        formData.monthOfGeneration = "2018-08-01";
        this.setState({ formData: formData})
    }

    handleSubmit(event) {
        event.preventDefault();
        let formData = this.state.formData;
        let validationResponse = validateFieldsOnSubmit(formData);
        this.setState({ error: validationResponse});
        if (validationResponse === "") {
            this.setState({spinnerActive: true});
            push_uploads(formData)
            .then( () => {
                this.setState({spinnerActive: false});
                this.props.onFormSuccess();
                this.setState({ error: ''});
            })
        }
    }

    render() {
        let useSolarEdgeApiCheckbox = this.state.formData.solarEdgeApiCheckbox;
        let doSolarEdgeReportCheckbox = this.state.formData.solarEdgeCheckbox;
        let doEnphaseReportCheckbox = this.state.formData.enphaseCheckbox;
        let doGrowattReportCheckbox = this.state.formData.growattCheckbox;
        let error = this.state.error;
        let spinnerActive = this.state.spinnerActive;
        return (
            <div>
            <div className={"left-row row-bottom-margin"}>
                Enphase: <CheckBox checked={doEnphaseReportCheckbox} toggleCheckBox={ () => {this.toggleCheckBox("enphaseCheckbox")}}/>
                Solar Edge: <CheckBox checked={doSolarEdgeReportCheckbox} toggleCheckBox={ () => {this.toggleCheckBox("solarEdgeCheckbox")}}/>
                Growatt: <CheckBox checked={doGrowattReportCheckbox} toggleCheckBox={ () => {this.toggleCheckBox("growattCheckbox")}}/>
            </div>
                {error ? <ErrorBox message={error}/> : ""}
            <TestButton handleClick={this.handleTestButtonClick}/>
            <form autoComplete="off" onSubmit={this.handleSubmit}>
            <label>
                Enphase File:
                <input name="EnphaseFileField" type="file" onChange={this.handleFileChange} disabled={!doEnphaseReportCheckbox}/>
            </label>
            API: <CheckBox checked={useSolarEdgeApiCheckbox} toggleCheckBox={ () => {this.toggleCheckBox("solarEdgeApiCheckbox")}} disabled={!doSolarEdgeReportCheckbox}/>
            <label>
                Solar Edge File:
                <input name="SolarEdgeFileField" type="file" onChange={this.handleFileChange} disabled={!doSolarEdgeReportCheckbox || useSolarEdgeApiCheckbox}/>
            </label>
            <label>
                GATS File:
                <input name="GATSFileField" type="file"  onChange={this.handleFileChange} />
            </label>
            <label>
                Date Of Reading:
                <input name="dateOfReading" type="date" value={this.state.formData.dateOfReading} onChange={this.handleChange} />
            </label>
            <label>
                Month Of Generation:
                <input name="monthOfGeneration" type="month" value={this.state.formData.monthOfGeneration} onChange={this.handleChange} />
            </label>
            <input type="submit" value="Submit" disabled={spinnerActive}/><img style={ {width: "30px"} } src={process.env.PUBLIC_URL + "/img/spinner.gif"} className={spinnerActive ? '' : 'hidden'}/>
            </form>
            </div>
        );
    }
}

function CheckBox(props) {
    return <input className={"checkbox"} type={"checkbox"} checked={props.checked} onChange={props.toggleCheckBox} disabled={props.disabled}/>
}

function validateFieldsOnSubmit(formData) {
    if (!formData.enphaseCheckbox && !formData.solarEdgeCheckbox && !formData.growattCheckbox) {
        return "You must select at least one monitoring type to a run a report.";
    }
    else if (formData.dateOfReading === "" || formData.monthOfGeneration === "") {
        return "You must enter a date of reading and a month of generation."
    }
    else if (new Date(formData.dateOfReading).getTime() < new Date(formData.monthOfGeneration).getTime()) {
        return "The date of reading must be later than month of generation.";
    }
    else if (formData.solarEdgeCheckbox && !formData.solarEdgeApiCheckbox && formData.SolarEdgeFileField === "") {
        return "You must enter a solar edge file.";
    }
    else if (formData.enphaseCheckbox && formData.EnphaseFileField === "") {
        return "You must enter an enphase file.";
    }
    else if(formData.GATSFileField === "") {
        return "You must enter a GATS file";
    }
    return "";
}