import {objectToArray} from "../components/util/utils";

const objectToArrayWithOrder = objectToArray(["Online_Monitor_Id", "Online_Monitor_Type","Online_Monitor_Name", "GATS_Name", "Non_Number", "Status","API_KEY"]);

export const get_uploads = () => {
    return fetch(process.env.REACT_APP_API_URL + '/upload')
    .then( (response) => {
        return response.json();
    })
};


export const get_records = () => {
    return fetch(process.env.REACT_APP_API_URL + '/record')
    .then( (response) => {
        return response.json();
    })
    .then( (body) => {

        let records = body.value;
        let array_records = [];
        for (let record of records) {
            array_records.push(objectToArrayWithOrder(record));
        }
        return array_records;
    })
};

export const get_record = (id) => {
    return fetch(process.env.REACT_APP_API_URL + '/record/' + id)
    .then( (response) => {
        return response.json();
    })
    .then( (body) => {
        if (!body.value) return null;
        return objectToArrayWithOrder(body.value[0]);
    })
};


export const push_uploads = (formData) => {
    const formPostData = new FormData();
    for (let key  of Object.keys(formData)) {
        formPostData.append(key, formData[key]);
    }
    return fetch(process.env.REACT_APP_API_URL + '/upload', {
        method: "POST",
        body: formPostData
    })
};

export const push_records = (formData) => {
    return fetch(process.env.REACT_APP_API_URL + '/record', {
        method: "POST",
        body: JSON.stringify(formData)
    })
};

export const update_record = (id, formData) => {
    return fetch(process.env.REACT_APP_API_URL + '/record/' + id, {
        method: "PUT",
        body: JSON.stringify(formData)
    });
};

export const delete_record = (id, type) => {
    return fetch( process.env.REACT_APP_API_URL + '/record/' + type.replace(" ","-") + "/" + id, {
        method: "DELETE",
    });
};










