import React from 'react';
import {Container, Col, Row} from 'reactstrap';
import Table from '../../util/Table';

import RecordForm, {RecordUpdateForm} from './RecordForm';



import './RecordTable.css';
import SearchBox from "../../util/SearchBox";


export default class RecordTable extends Table {


    renderHeaderRow() {
        const {headers} = this.props || null;
        if (headers) {
            return (
                    <Row key={"header"}>
                {
                    headers.map( (header, i) => {
                        return <Col key={header}  className={[2,3,4].includes(i) ? "col-large" :"col-small"}><Cell key={header}  header={true} value={header} /></Col>
                    })
                }
                    </Row>
                  )
        }
    }

    renderBody() {
        const {tableData} = this.props;
        return (<Container>
        {
            tableData.sort( (a,b) => {
                if (!a[2]) { a[2] = ""}
                if (!b[2]) {b[2] = ""}
                return a[2].localeCompare(b[2])}).map((row, row_index) => {
                return <RecordUpdateForm key={row} input={row} onTableLoad={this.props.onTableLoad.bind(this)}/>
            })
        }</Container>);
    };

    render() {
        const {title} = this.props;
        const tableHeader = this.renderHeaderRow();
        const tableBody = this.renderBody();
        return <div className="table-wrapper"> {
            ((this.props.search === true) && <table className="table-header"><thead><tr key={"header-row"}><td key={"title"}><h3>{title}</h3></td><td key={"search"}><SearchBox onTableLoad={this.props.onTableLoad.bind(this)} /></td></tr></thead></table>) || (<h3>{title}</h3>)
        }
            <div className="">
                <Container>
                {tableHeader}
                    <hr/>
                {tableBody}
                </Container>
            </div>
        </div>

    };



}


let Cell = function(props) {
    if (props.header) {
        return <div className={"cell"}>{props.value}</div>
    }
    else if (props.buttons) {
        return <div>
            <table className={"no-border action-table"}><tbody><tr>{props.buttons.map( (buttonName) => { return <td key={`${buttonName}-button-row`}><button>{buttonName}</button></td>})}</tr></tbody></table>
        </div>
    }
    return (
        <input type="text" value={props.value} className="cell"/>
    );
};
