import React, {Component} from 'react';

import {get_record, get_records} from "../../api/api";


export default class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = { searchValue: ""}
    }

    handleChange(event) {
        let id = event.target.value;
        if (!id) {
            this.setState({ searchValue: ""});
            get_records()
            .then( (records) => {
                this.props.onTableLoad(records)
            });
        }
        else if (id.match(/\s+/)) {
            this.setState({ searchValue: id});
            this.props.onTableLoad([]);
        }
        else {
            this.setState({ searchValue: id});
            get_record(id).then( (array) => {
                if (!array || array.length === 0){
                    this.props.onTableLoad([]);
                }
                else {
                    this.props.onTableLoad([array]);
                }
            });
        }


    }

    render() {
        return  <div>
            <input placeholder="NON Number" className="search-box" type={"text"} value={this.state.searchValue} onChange={this.handleChange.bind(this)}/>
        </div>
    }

}




